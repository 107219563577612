"use client";

import Error from "next/error";

function NotFound() {
  return (
    <html lang="en">
      <body style={{ background: "#000", color: "#fff" }}>
        <Error statusCode={404} />
      </body>
    </html>
  );
}

export default NotFound;
